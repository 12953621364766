<!-- 라이브인포 등록 폼 -->
<template>
  <div>
    <validation-observer ref="liveinfoFormRules">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="CalendarIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              게시기간
            </h4>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="게시기간(Fr)"
              label-for="liveinfo-start-date"
            >
              <validation-provider
                #default="{ errors }"
                name="게시기간(Fr)"
                :rules="{ required: true, before: liveinfo.endDate }"
              >
                <b-form-datepicker
                  id="liveinfo-start-date"
                  v-model="liveinfo.startDate"
                  placeholder="게시기간(Fr)"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="게시기간(To)"
              label-for="liveinfo-end-date"
            >
              <validation-provider
                #default="{ errors }"
                name="게시기간(To)"
                :rules="{ required: true, after: liveinfo.startDate }"
              >
                <b-form-datepicker
                  id="liveinfo-end-date"
                  v-model="liveinfo.endDate"
                  placeholder="게시기간(To)"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="UserCheckIcon"
              size="19"
            />

            <h4
              v-show="contentType != 2"
              class="mb-0 ml-50"
            >
              모아보기/라이브DI/팝업 알림 노출 조건
            </h4>
            <h4
              v-show="contentType == 2"
              class="mb-0 ml-50"
            >
              팝업 알림 노출 조건
            </h4>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="10">
            <b-form-group
              label="진료과"
              label-for="liveinfo-cond-dept"
            >
              <validation-provider
                #default="{ errors }"
                name="진료과"
                rules="required"
              >
                <b-form-input
                  id="liveinfo-cond-dept"
                  v-model="liveinfo.condDeptNames"
                  readonly
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showMediDeptSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="contentType == 1">
          <b-col cols="12">
            <label>진료과 세팅 정보로 닥터인포에 노출하기</label>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group>
              <b-form-radio-group
                v-model="liveinfo.isCondDeptDrInfo"
              >
                <b-form-radio
                  class="custom-control-primary"
                  value="true"
                >
                  사용
                </b-form-radio>
                <b-form-radio
                  class="custom-control-secondary"
                  value="false"
                >
                  사용안함
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <b-form-group
              label="전공과"
              label-for="liveinfo-cond-major"
            >
              <validation-provider
                #default="{ errors }"
                name="전공과"
                rules="required"
              >
                <b-form-input
                  id="liveinfo-cond-major"
                  v-model="liveinfo.condMajorNames"
                  readonly
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showMajorSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <b-form-group
              label="지역"
              label-for="liveinfo-cond-region"
            >
              <validation-provider
                #default="{ errors }"
                name="지역"
                rules="required"
              >
                <b-form-input
                  id="liveinfo-cond-region"
                  v-model="liveinfo.condRegionNames"
                  readonly
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showRegionSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <label>연령</label>
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="d-flex align-items-center justify-content-start"
          >
            <validation-provider
              #default="{ errors }"
              name="시작나이"
              rules="required"
            >
              <b-form-input
                v-model="liveinfo.minAge"
                @keyup="restrictToNumbers"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <label class="ml-50 mr-50">~</label>
            <validation-provider
              #default="{ errors }"
              name="끝나이"
              rules="required"
            >
              <b-form-input
                v-model="liveinfo.maxAge"
                @keyup="restrictToNumbers"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <p />
        <p>* 처방 팝업 및 스케줄 팝업을 사용하지 않을 경우, 팝업 알림 노출 조건을 임의의 값(ex. 전체 진료과)으로 설정해주세요</p>
      </b-card>
    </validation-observer>
    <!-- 메디컬트랜드는 모아보기 설정 제외 -->
    <b-card v-show="contentType != 2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="GridIcon"
            size="19"
          />
          <h4
            class="mb-0 ml-50"
            style="min-width: 120px"
          >
            모아보기
          </h4>
          <b-form-checkbox
            v-model="liveinfo.isMoaView"
            switch
            inline
            class="custom-control-info"
          >
            {{ liveinfo.isMoaView ? '사용' : '사용안함' }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <validation-observer ref="liveinfoMoaRules">
        <b-row
          v-show="liveinfo.isMoaView"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="게시기간(Fr)"
              label-for="liveinfo-moa-start-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="게시기간(Fr)"
                :rules="{
                  required: true,
                  before: liveinfo.moaEndDate,
                  dateBetween: [liveinfo.startDate, liveinfo.endDate],
                }"
              >
                <b-form-datepicker
                  id="liveinfo-moa-start-date"
                  v-model="liveinfo.moaStartDate"
                  placeholder="게시기간(Fr)"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '모아보기 기간은 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="게시기간(To)"
              label-for="liveinfo-moa-end-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="게시기간(To)"
                :rules="{
                  required: true,
                  after: liveinfo.moaStartDate,
                  dateBetween: [liveinfo.startDate, liveinfo.endDate],
                }"
              >
                <b-form-datepicker
                  id="liveinfo-moa-end-date"
                  v-model="liveinfo.moaEndDate"
                  placeholder="게시기간(To)"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '모아보기 기간은 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="상단 고정"
              label-for="liveinfo-is-top-fix"
            >
              <b-form-radio-group
                v-model="liveinfo.isTopFix"
                @change="isTopFixChange"
              >
                <b-form-radio
                  class="custom-control-primary"
                  :value="true"
                >
                  사용
                </b-form-radio>
                <b-form-radio
                  class="custom-control-secondary"
                  :value="false"
                >
                  사용안함
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            v-show="liveinfo.isTopFix"
            cols="12"
            md="2"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="노출 영역"
                rules="required"
              >
                <v-select
                  id="liveinfo-topFixLevel"
                  v-model="liveinfo.topFixLevel"
                  :options="codes.topFixLevel"
                  :reduce="(option) => option.code"
                  :state="errors.length > 0 ? false : null"
                  :editable="false"
                  :disabled="!liveinfo.isTopFix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <!-- 라이브DI는 약품정보에서만 사용 -->
    <b-card v-show="contentType == 1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="19"
          />
          <h4
            class="mb-0 ml-50"
            style="min-width: 120px"
          >
            라이브 DI
          </h4>
          <b-form-checkbox
            v-model="liveinfo.isLiveDi"
            switch
            inline
            class="custom-control-info"
          >
            {{ liveinfo.isLiveDi ? '사용' : '사용안함' }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <validation-observer ref="liveinfoIsLiveDiRules">
        <b-row
          v-if="liveinfo.isLiveDi"
          class="mt-1"
        >
          <b-col>
            <b-form-group
              label="노출 메시지"
              label-for="liveinfo-livedi-title"
            >
              <validation-provider
                #default="{ errors }"
                name="노출 메시지"
                rules="required"
              >
                <b-form-input
                  id="liveinfo-livedi-title"
                  v-model="liveinfo.liveDiTitle"
                  placeholder="입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- 라이브DI 구간설정 START -->
        <b-row
          v-if="liveinfo.isLiveDi"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group>
              <label for="liveinfo-livedi-title">노출 구간</label>
              <label class="ml-50 text-secondary"> (게시기간 : </label>
              <label class="ml-50 text-primary">{{ `${!(liveinfo.startDate) ? '미지정' : liveinfo.startDate}` }}</label>
              <label class="ml-50 text-secondary">~</label>
              <label class="ml-50 text-primary">{{ ` ${!(liveinfo.endDate) ? '미지정' : liveinfo.endDate}` }}</label>
              <label class="text-secondary">) </label>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="7"
          >
            <b-form-group>
              <!-- <label class="ml-50 text-secondary">현재 게시기간 : </label>
              <label class="ml-50 text-primary">{{ `${!(liveinfo.startDate) ? '미지정' : liveinfo.startDate}` }}</label>
              <label class="ml-50 text-secondary">~</label>
              <label class="ml-50 text-primary">{{ ` ${!(liveinfo.endDate) ? '미지정' : liveinfo.endDate}` }}</label> -->
              <b-badge
                class="ml-50"
                variant="light-primary"
                style="cursor: pointer"
                @click="addLiveDiSection"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                구간 추가
              </b-badge>
            </b-form-group>
          </b-col>
          <!-- <b-col
            cols="12"
            md="2"
          >
            <b-form-group>
              <b-badge
                class="ml-50"
                variant="light-primary"
                style="cursor: pointer"
                @click="addLiveDiSection"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                구간 추가
              </b-badge>
            </b-form-group>
          </b-col> -->

        </b-row>

        <template
          v-if="liveinfo.isLiveDi"
        >
          <b-row
            v-for="(r, idx) in liveinfo.liveDiSchedules"
            :id="`livedi-${idx}`"
            :key="idx"
            style="margin-top: 5px"
          >
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors, failedRules }"
                  name="알림기간(Fr)"
                  :rules="{
                    required: true,
                    before: r.endDate,
                    dateBetween: [liveinfo.startDate, liveinfo.endDate],
                    dateOverlapStart: [idx, liveinfo.liveDiSchedules]
                  }"
                >
                  <b-form-datepicker
                    v-model="r.startDate"
                    size="sm"
                    placeholder="알림기간(Fr)"
                    :readonly="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    :state="errors.length > 0 ? false : null"
                    :date-disabled-fn="dateDisabled"
                    :style="getInputGroupStyle('', (r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                    :disabled="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    @id="`liveinfo-livedi-start-date-${idx}`"
                  />
                  <small class="text-danger">
                    {{
                      failedRules.hasOwnProperty('required')
                        ? '필수 입력값입니다.'
                        : errors[0]
                          +
                          failedRules.hasOwnProperty('before')
                          ? '구간 종료일보다 늦을 수 없습니다.'
                          : errors[0]
                            +
                            failedRules.hasOwnProperty('dateOverlapStart')
                            ? '다른 구간과 중복됩니다.'
                            : errors[0]
                              +
                              failedRules.hasOwnProperty('dateBetween')
                              ? '구간은 게시기간 이내로 제한됩니다.'
                              : errors[0]
                    }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors, failedRules }"
                  name="알림기간(To)"
                  :rules="{
                    required: true,
                    after: r.startDate,
                    dateBetween: [liveinfo.startDate, liveinfo.endDate],
                    dateOverlapEnd: [idx, liveinfo.liveDiSchedules]
                  }"
                >
                  <b-form-datepicker
                    v-model="r.endDate"
                    size="sm"
                    placeholder="알림기간(To)"
                    :readonly="(r.endDate !== null) && dateDisabled(r.endDate, r.endDate)"
                    :state="errors.length > 0 ? false : null"
                    :date-disabled-fn="dateDisabled"
                    :style="getInputGroupStyle('', (r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                    :disabled="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    @id="`liveinfo-livedi-end-date-${idx}`"
                  />
                  <small class="text-danger">
                    {{
                      failedRules.hasOwnProperty('required')
                        ? '필수 입력값입니다.'
                        : errors[0]
                          +
                          failedRules.hasOwnProperty('after')
                          ? '구간 시작일보다 빠를 수 없습니다.'
                          : errors[0]
                            +
                            failedRules.hasOwnProperty('dateOverlapEnd')
                            ? '다른 구간과 중복됩니다.'
                            : errors[0]
                              +
                              failedRules.hasOwnProperty('dateBetween')
                              ? '구간은 게시기간 이내로 제한됩니다.'
                              : errors[0]
                    }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="1차"
                :rules="{
                  required: true,
                  less: parseInt(r.secondCount),
                }"
              >
                <b-input-group
                  size="sm"
                  class="input-group-merge"
                >
                  <b-input-group-prepend>
                    <b-input-group-text
                      class="form-control"
                      :style="getInputGroupStyle('sm', (r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                      :readonly="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    >
                      1차
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <cleave
                    v-model="r.firstCount"
                    maxlength="3"
                    class="form-control"
                    :disabled="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    :raw="false"
                    :options="cleave.number"
                    placeholder="회차 수를 입력하세요"
                    style="text-align: end;"
                    :state="errors.length > 0 ? false:null"
                    @id="`liveinfo-livedi-first-count-${idx}`"
                  />
                  <b-input-group-append>
                    <b-input-group-text
                      class="form-control"
                      :style="getInputGroupStyle('sm', (r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                      :readonly="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    >
                      회
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="2차"
                :rules="{
                  required: true,
                  more: parseInt(r.firstCount),
                }"
              >
                <b-input-group
                  size="sm"
                  class="input-group-merge"
                >
                  <b-input-group-prepend>
                    <b-input-group-text
                      class="form-control"
                      :style="getInputGroupStyle('sm', (r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                      :readonly="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    >
                      2차
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <cleave
                    v-model="r.secondCount"
                    maxlength="3"
                    class="form-control"
                    :disabled="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    :raw="false"
                    :options="cleave.number"
                    placeholder="회차 수를 입력하세요"
                    style="text-align: end;"
                    :state="errors.length > 0 ? false:null"
                    @id="`liveinfo-livedi-second-count-${idx}`"
                  />
                  <b-input-group-append>
                    <b-input-group-text
                      class="form-control "
                      :style="getInputGroupStyle('sm', (r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                      :readonly="(r.startDate !== null) && dateDisabled(r.startDate, r.startDate)"
                    >
                      회
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="1"
            >
              <b-button-toolbar>
                <b-button-group size="sm">
                  <b-button
                    v-if="!((r.startDate !== null) && dateDisabled(r.startDate, r.startDate))"
                    variant="flat-danger"
                    pill
                    @click="removeLiveDiSection(idx)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
        </template>
        <!-- 라이브DI 구간설정 END -->

        <b-row
          v-if="liveinfo.isLiveDi"
          class="mt-1"
        >
          <b-col
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label="라이브DI 교차조건"
              label-for="liveinfo-is-livedi-or"
            >
              <b-form-radio-group
                id="liveinfo-is-livedi-or"
                v-model="liveinfo.isLiveDiOr"
                :options="codes.conditionOr"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="청구코드"
              :rules="{
                requiredIf:
                  !liveinfo.liveDiCondDiseaseCodes && !liveinfo.liveDiCondLabCodes,
              }"
            >
              <b-form-group
                label="청구코드"
                label-for="liveinfo-livedi-drug-codes"
              >
                <b-form-input
                  id="liveinfo-livedi-drug-codes"
                  v-model="liveinfo.liveDiCondDrugCodes"
                  placeholder="코드를 입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    errors.length > 0
                      ? '라이브 DI 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다.'
                      : ''
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="질병코드"
              :rules="{
                requiredIf: !liveinfo.liveDiCondDrugCodes && !liveinfo.liveDiCondLabCodes,
              }"
            >
              <b-form-group
                label="질병코드"
                label-for="liveinfo-livedi-disease-codes"
              >
                <b-form-input
                  id="liveinfo-livedi-disease-codes"
                  v-model="liveinfo.liveDiCondDiseaseCodes"
                  placeholder="코드를 입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    errors.length > 0
                      ? '라이브 DI 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다.'
                      : ''
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="검사코드"
              :rules="{
                requiredIf:
                  !liveinfo.liveDiCondDrugCodes && !liveinfo.liveDiCondDiseaseCodes,
              }"
            >
              <b-form-group
                label="검사코드 (※ 검사는 원코드 5자리로 입력되어야 합니다. e.g. 'D2280000'[X] 'D2280'[O])"
                label-for="liveinfo-livedi-lab-codes"
              >
                <b-form-input
                  id="liveinfo-livedi-lab-codes"
                  v-model="liveinfo.liveDiCondLabCodes"
                  placeholder="코드를 입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    errors.length > 0
                      ? '라이브 DI 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다.'
                      : ''
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="BellIcon"
            size="19"
          />
          <h4
            class="mb-0 ml-50"
            style="min-width: 120px"
          >
            처방 팝업
          </h4>
          <b-form-checkbox
            v-model="liveinfo.isInfoPopUp"
            switch
            inline
            class="custom-control-info"
          >
            {{ liveinfo.isInfoPopUp ? '사용' : '사용안함' }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <validation-observer ref="liveinfoIsInfoPopRules">
        <b-row
          v-if="liveinfo.isInfoPopUp"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="알림기간(Fr)"
              label-for="liveinfo-ipu-start-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="알림기간(Fr)"
                :rules="{
                  required: true,
                  before: liveinfo.ipuEndDate,
                  dateBetween: [liveinfo.startDate, liveinfo.endDate],
                }"
              >
                <b-form-datepicker
                  id="liveinfo-ipu-start-date"
                  v-model="liveinfo.ipuStartDate"
                  placeholder="알림기간(Fr)"
                  :state="errors.length > 0 ? false : null"
                  @change="spuOneTimeChange()"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '처방 팝업 기간은 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="알림기간(To)"
              label-for="liveinfo-ipu-end-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="알림기간(To)"
                :rules="{
                  required: true,
                  after: liveinfo.ipuStartDate,
                  dateBetween: [liveinfo.startDate, liveinfo.endDate],
                }"
              >
                <b-form-datepicker
                  id="liveinfo-ipu-end-date"
                  v-model="liveinfo.ipuEndDate"
                  :state="errors.length > 0 ? false : null"
                  placeholder="알림기간(To))"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '처방 팝업 기간은 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="처방팝업 알림 시간"
              label-for="liveinfo-is-immediate"
            >
              <b-form-radio-group
                id="liveinfo-is-immediate"
                v-model="liveinfo.isImmediate"
                :options="codes.immediateOr"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="알림횟수"
              label-for="liveinfo-ipu-pop-count"
            >
              <b-form-spinbutton
                id="liveinfo-ipu-pop-count"
                v-model="liveinfo.ipuPopCount"
                min="1"
                max="10"
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="알림 유지시간"
              label-for="liveinfo-ipu-delay-sec"
            >
              <validation-provider
                #default="{ errors }"
                name="알림 유지시간"
                rules="required"
              >
                <v-select
                  id="liveinfo-ipu-delay-sec"
                  v-model="liveinfo.ipuDelaySec"
                  :options="codes.selectTimes"
                  :reduce="(option) => option.code"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          />
          <div v-if="!liveinfo.isImmediate">
            <b-col cols="12">
              <label>알림 시간 선택</label>
            </b-col>
            <b-col
              v-for="(sched, idx) in liveinfo.schedules"
              :id="`schedules${sched.id}`"
              :key="sched.id"
              cols="12"
              class="d-flex align-items-center justify-content-start"
              :class="{ 'mt-25': idx !== 0 }"
            >
              <template v-if="sched.popUpType === 1">
                <validation-provider
                  #default="{ errors }"
                  name="알림 시간 선택"
                  rules="required"
                >
                  <v-date-picker
                    v-model="sched.noticeTime"
                    type="time"
                    format="HH:mm"
                    value-type="format"
                    placeholder="HH:mm"
                    :time-picker-options="{
                      start: '08:00',
                      step: '00:05',
                      end: '20:00',
                    }"
                    class="mr-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-button
                  v-if="idx !== getSchedIdxFirstOrLast(1, true)"
                  variant="outline-danger"
                  size="sm"
                  class="mr-1"
                  @click="liveinfo.schedules.splice(idx, 1)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>삭제</span>
                </b-button>
                <b-button
                  v-if="idx === getSchedIdxFirstOrLast(1, false)"
                  variant="outline-primary"
                  size="sm"
                  @click="
                    liveinfo.schedules.push({
                      noticeTime: null,
                      popUpType: 1,
                    })
                  "
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>추가</span>
                </b-button>
              </template>
            </b-col>
          </div>
          <b-col
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label="팝업 교차조건"
              label-for="liveinfo-is-info-popup-or"
            >
              <b-form-radio-group
                id="liveinfo-is-info-popup-or"
                v-model="liveinfo.isInfoPopUpOr"
                :options="codes.conditionOr"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="청구코드"
              :rules="{
                requiredIf:
                  !liveinfo.condDiseaseCodes && !liveinfo.condLabCodes,
              }"
            >
              <b-form-group
                label="청구코드"
                label-for="liveinfo-drug-codes"
              >
                <b-form-input
                  id="liveinfo-drug-codes"
                  v-model="liveinfo.condDrugCodes"
                  placeholder="코드를 입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    errors.length > 0
                      ? '처방팝업 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다.'
                      : ''
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="질병코드"
              :rules="{
                requiredIf: !liveinfo.condDrugCodes && !liveinfo.condLabCodes,
              }"
            >
              <b-form-group
                label="질병코드"
                label-for="liveinfo-disease-codes"
              >
                <b-form-input
                  id="liveinfo-disease-codes"
                  v-model="liveinfo.condDiseaseCodes"
                  placeholder="코드를 입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    errors.length > 0
                      ? '처방팝업 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다.'
                      : ''
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="검사코드"
              :rules="{
                requiredIf:
                  !liveinfo.condDrugCodes && !liveinfo.condDiseaseCodes,
              }"
            >
              <b-form-group
                label="검사코드 (※ 검사는 원코드 5자리로 입력되어야 합니다. e.g. 'D2280000'[X] 'D2280'[O])"
                label-for="liveinfo-lab-codes"
              >
                <b-form-input
                  id="liveinfo-lab-codes"
                  v-model="liveinfo.condLabCodes"
                  placeholder="코드를 입력하세요"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    errors.length > 0
                      ? '처방팝업 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다.'
                      : ''
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="알림창 내용 (※ 처방팝업과 스케줄팝업 문구는 반드시 다르게 설정하여 주세요.)"
              label-for="liveinfo-ipu-content"
            >
              <validation-provider
                #default="{ errors }"
                name="알림창 내용"
                rules="required|popupContent"
              >
                <tinymce-editor
                  v-model="liveinfo.ipuContent"
                  :is-table="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="ClockIcon"
            size="19"
          />
          <h4
            class="mb-0 ml-50"
            style="min-width: 120px"
          >
            스케줄 팝업
          </h4>
          <b-form-checkbox
            v-model="liveinfo.isScheduleUse"
            switch
            inline
            class="custom-control-info"
          >
            {{ liveinfo.isScheduleUse ? '사용' : '사용안함' }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <validation-observer ref="liveinfoIsScheduleRules">
        <b-row
          v-if="liveinfo.isScheduleUse"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="알림기간(Fr)"
              label-for="liveinfo-spu-start-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="알림기간(Fr)"
                :rules="{
                  required: true,
                  before: liveinfo.spuEndDate,
                  dateBetween: [liveinfo.startDate, liveinfo.endDate],
                }"
              >
                <b-form-datepicker
                  id="liveinfo-spu-start-date"
                  v-model="liveinfo.spuStartDate"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '스케줄 팝업 기간은 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!liveinfo.isSpuOneTime"
            cols="12"
            md="3"
          >
            <b-form-group
              label="알림기간(To)"
              label-for="liveinfo-spu-end-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="알림기간(To)"
                :rules="{
                  required: true,
                  after: liveinfo.spuStartDate,
                  dateBetween: [liveinfo.startDate, liveinfo.endDate],
                }"
              >
                <b-form-datepicker
                  id="liveinfo-spu-end-date"
                  v-model="liveinfo.spuEndDate"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '스케줄 팝업 기간은 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--
          해당 기능은 사업/기획 협의하에 보류하고 일회 알람의 경우 전날 설정하는 것으로 협의
          <b-col
            cols="12"
            md="3"
          >
            <span class="font-weight-bold">일회 알림</span>
            <b-form-checkbox
              v-model="liveinfo.isSpuOneTime"
              class="custom-control-info mt-1 ml-1"
              @change="spuOneTimeChange()"
            />
          </b-col> -->
          <b-col
            cols="12"
            md="6"
          />
          <b-col cols="12">
            <b-form-group
              label="알림 유지시간"
              label-for="liveinfo-spu-delay-sec"
            >
              <validation-provider
                #default="{ errors }"
                name="알림 유지시간"
                rules="required"
              >
                <v-select
                  id="liveinfo-spu-delay-sec"
                  v-model="liveinfo.spuDelaySec"
                  :options="codes.selectTimes"
                  :reduce="(option) => option.code"
                  :state="errors.length > 0 ? false : null"
                  style="width: 210px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label>알림 시간 선택</label>
          </b-col>
          <b-col
            v-for="(sched, idx) in liveinfo.schedules"
            :id="`schedules${sched.id}`"
            :key="sched.id"
            cols="12"
            class="d-flex align-items-center justify-content-start"
            :class="{ 'mt-25': idx !== 0 }"
          >
            <template v-if="sched.popUpType === 2">
              <validation-provider
                #default="{ errors }"
                name="알림 시간 선택"
                rules="required"
              >
                <v-date-picker
                  v-model="sched.noticeTime"
                  type="time"
                  format="HH:mm"
                  value-type="format"
                  placeholder="HH:mm"
                  :time-picker-options="{
                    start: '08:00',
                    step: '00:05',
                    end: '20:00',
                  }"
                  class="mr-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-button
                v-if="idx !== getSchedIdxFirstOrLast(2, true)"
                variant="outline-danger"
                size="sm"
                class="mr-1"
                @click="liveinfo.schedules.splice(idx, 1)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>삭제</span>
              </b-button>
              <b-button
                v-if="idx === getSchedIdxFirstOrLast(2, false)"
                variant="outline-primary"
                size="sm"
                @click="
                  liveinfo.schedules.push({
                    noticeTime: null,
                    popUpType: 2,
                  })
                "
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>추가</span>
              </b-button>
            </template>
          </b-col>
          <b-col
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label="알림창 내용 (※ 처방팝업과 스케줄팝업 문구는 반드시 다르게 설정하여 주세요.)"
              label-for="liveinfo-spu-content"
            >
              <validation-provider
                #default="{ errors }"
                name="알림창 내용"
                rules="required|popupContent"
              >
                <tinymce-editor
                  v-model="liveinfo.spuContent"
                  :is-table="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

    <major-select
      :show-major-select="showMajorSelect"
      :selected-value="liveinfo.condMajorCodes"
      @close="majorSelect.close"
    />

    <region-select
      :show-region-select="showRegionSelect"
      :selected-value="liveinfo.condRegionCodes"
      @close="regionSelect.close"
    />

    <medi-dept-select
      :show-medi-dept-select="showMediDeptSelect"
      :selected-value="liveinfo.condDeptCodes"
      @close="mediDeptSelect.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import {
  BFormSpinbutton, BInputGroup, BBadge, VBTooltip,
  BInputGroupPrepend, BInputGroupAppend, BInputGroupText,
  BButtonToolbar, BButtonGroup, BButton, BFormInput,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, getCurrentInstance,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
  between,
  dateBetween,
  after,
  before,
  requiredIf,
} from '@validations'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { errorFormatter, arrayJoinStr } from '@core/utils/filter'
import MajorSelect from '@/views/apps/popup/MajorSelect.vue'
import RegionSelect from '@/views/apps/popup/RegionSelect.vue'
import MediDeptSelect from '@/views/apps/popup/MediDeptSelect.vue'
import TinymceEditor from '@/views/TinyMCE.vue'
import Cleave from 'vue-cleave-component'
import { cloneDeep } from 'lodash'

export default {
  components: {
    Cleave,
    vSelect,
    BFormSpinbutton,
    BInputGroup,
    BBadge,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormInput,
    BFormDatepicker,
    MajorSelect,
    RegionSelect,
    MediDeptSelect,
    ValidationProvider,
    ValidationObserver,
    TinymceEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    liveinfoId: {
      type: Number,
      default: null,
      required: false,
    },
    contentType: {
      type: Number,
      default: null,
      required: false,
    },
    clearFlag: {
      type: Boolean,
      required: true,
    },
    validFlag: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    // 클래스가 변경되었을 때 감시
    const targetElement = document.body
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          // 클래스가 변경되면 isDarkMode 변수를 업데이트
          this.isDarkMode = targetElement.classList.contains('dark-layout')
        }
      })
    })
    observer.observe(targetElement, { attributes: true })
  },
  setup(props, { emit }) {
    watch(
      () => props.clearFlag,
      () => {
        resetLiveinfoData()
      },
    )
    watch(
      () => props.validFlag,
      () => {
        validate()
      },
    )
    watch(
      () => props.liveinfoId,
      newVal => {
        fetchLiveInfo(newVal)
      },
    )

    extend('popupContent', {
      validate: value => {
        if (value !== null) {
          const val = value.substring(value.indexOf('<td>') + 4, value.indexOf('</td>')).replace(/&nbsp;/g, '')

          if (val.trim().length === 0) return '[{_field_}]는(은) 필수 입력입니다.'
        }

        return true
      },
    })

    const isDarkMode = ref(document.body.classList.contains('dark-layout'))

    const getInputGroupStyle = (size, disabled) => {
      let rsStyle = ''

      // size 가 sm인 경우 높이 제한
      if (size === 'sm') {
        rsStyle += "height: 2.142rem;"
      }

      // dark-layout 클래스가 있으면 스타일 적용
      if (isDarkMode.value && disabled) {
        rsStyle += " opacity: 0.5;"
      }

      return rsStyle
    }

    const dateDisabled = (ymd, date) => {
      // const weekday = date.getDay()
      // const day = date.getDate()
      // // 주말 비활성화 (Sunday = `0`, Saturday = `6`) + 11일 비활성화
      // return weekday === 0 || weekday === 6 || day === 11

      const today = dayjs().format('YYYY-MM-DD')
      // 오늘 이전 날짜 선택 불가 (익일부터 선택 가능)
      return new Date(ymd) < new Date(today)
    }

    const getSchedIdxFirstOrLast = (popUpType, isFirst) => {
      let rIdx = -1
      for (let i = 0; i < liveinfo.value.schedules.length; i += 1) {
        if (popUpType === liveinfo.value.schedules[i].popUpType) {
          rIdx = i
          if (isFirst) return rIdx
        }
      }
      return rIdx
    }

    const toast = useToast()
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const cleave = ref({
      number: {
        creditCard: true,
      },
    })

    // Set Codes
    const codes = ref({
      conditionOr: [
        { label: 'OR', code: true },
        { label: 'AND', code: false },
      ],
      immediateOr: [
        { label: '즉시', code: true },
        { label: '선택', code: false },
      ],
      selectTimes: [
        { label: '10초', code: 10 },
        { label: '30초', code: 30 },
        { label: '1분', code: 60 },
        { label: '5분', code: 300 },
      ],
      topFixLevel: [
        { label: '1번', code: 1 },
        { label: '2번', code: 2 },
        { label: '3번', code: 3 },
        { label: '4번', code: 4 },
        { label: '5번', code: 5 },
      ],
    })

    const editorOptions = {
      placeholder: '팝업 알림창 내용을 입력하세요',
      preserveWhitespace: true,
    }

    const addLiveDiSection = () => {
      liveinfo.value.liveDiSchedules.push({
        startDate: null,
        endDate: null,
        firstCount: 1,
        secondCount: 10,
      })
    }

    const removeLiveDiSection = idx => {
      liveinfo.value.liveDiSchedules.splice(idx, 1)
    }

    const liveinfoInitState = {
      liveInfoId: null,
      contentType: null,
      title: null,
      isUse: true,
      htmlContent: null,

      // 게시 기간
      startDate: null,
      endDate: null,

      // 알림 노출 조건
      condDeptCodes: null,
      condDeptNames: null,
      condMajorCodes: null,
      condMajorNames: null,
      condRegionCodes: null,
      condRegionNames: null,
      minAge: 0,
      maxAge: 120,

      // 모아보기
      isMoaView: false,
      moaStartDate: null,
      moaEndDate: null,

      // 상단고정
      isTopFix: false,
      topFixLevel: 1,

      // 처방 팝업
      isInfoPopUp: false,
      isInfoPopUpOr: false,
      ipuContent: null,
      ipuStartDate: null,
      ipuEndDate: null,
      ipuPopCount: 1,
      isImmediate: true,
      ipuDelaySec: 10,

      condDrugCodes: null,
      condDiseaseCodes: null,
      condLabCodes: null,

      // 라이브DI
      isLiveDi: false,
      liveDiTitle: null,
      liveDiSchedules: [],
      isLiveDiOr: false,
      liveDiCondDrugCodes: null,
      liveDiCondDiseaseCodes: null,
      liveDiCondLabCodes: null,

      // 스케줄 팝업
      isScheduleUse: false,
      spuStartDate: null,
      spuEndDate: null,
      spuContent: null,
      spuDelaySec: 10,

      isCondDeptDrInfo: false,

      schedules: [
        { noticeTime: null, popUpType: 1 }, // 처방 알림
        { noticeTime: null, popUpType: 2 }, // 스케줄 알림
      ],

      isSpuOneTime: false,
    }

    const liveinfo = ref(cloneDeep(liveinfoInitState))

    const resetLiveinfoData = () => {
      liveinfoInitState.liveDiSchedules = []
      Object.assign(liveinfo.value, liveinfoInitState)
    }

    const showMediDeptSelect = ref(false)
    const mediDeptSelect = {
      close: rs => {
        showMediDeptSelect.value = false

        if (rs) {
          liveinfo.value.condDeptCodes = arrayJoinStr(rs, 'code')
          liveinfo.value.condDeptNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const showMajorSelect = ref(false)
    const majorSelect = {
      close: rs => {
        showMajorSelect.value = false

        if (rs) {
          liveinfo.value.condMajorCodes = arrayJoinStr(rs, 'code')
          liveinfo.value.condMajorNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const showRegionSelect = ref(false)
    const regionSelect = {
      close: rs => {
        showRegionSelect.value = false

        if (rs) {
          liveinfo.value.condRegionCodes = arrayJoinStr(rs, 'code')
          liveinfo.value.condRegionNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const validate = () => {
      const validations = []
      validations.push(refs.liveinfoFormRules.validate())

      if (liveinfo.value.isMoaView) {
        validations.push(refs.liveinfoMoaRules.validate())
      }

      if (liveinfo.value.isInfoPopUp) {
        validations.push(refs.liveinfoIsInfoPopRules.validate())
      }

      if (liveinfo.value.isLiveDi) {
        validations.push(refs.liveinfoIsLiveDiRules.validate())
      }

      if (liveinfo.value.isScheduleUse) {
        validations.push(refs.liveinfoIsScheduleRules.validate())
      }

      Promise.all(validations).then(valids => {
        const result = valids.every(Boolean)
        if (result) {
          emit('callback', liveinfo.value)
        } else {
          emit('callback', null)
        }
      })
    }

    const fetchLiveInfo = id => {
      resetLiveinfoData()

      if (id === null) return
      axios
        .get(`/fa/liveinfo/${id}`)
        .then(rs => {
          const liveInfo = rs.data
          let ipuSched = false
          let spuSched = false

          if (liveInfo.schedules && liveInfo.schedules.length) {
            for (let i = 0; i < liveInfo.schedules.length; i += 1) {
              const sched = liveInfo.schedules[i]
              if (sched.popUpType === 1) {
                ipuSched = true
              }
              if (sched.popUpType === 2) {
                spuSched = true
              }
            }
          }
          if (ipuSched === false) {
            liveInfo.schedules.push({ noticeTime: null, popUpType: 1 })
          }
          if (spuSched === false) {
            liveInfo.schedules.push({ noticeTime: null, popUpType: 2 })
          }

          liveInfo.startDate = removeUTCformat(liveInfo.startDate)
          liveInfo.endDate = removeUTCformat(liveInfo.endDate)
          liveInfo.moaStartDate = liveInfo.moaStartDate !== null ? removeUTCformat(liveInfo.moaStartDate) : null
          liveInfo.moaEndDate = liveInfo.moaEndDate !== null ? removeUTCformat(liveInfo.moaEndDate) : null
          liveInfo.ipuStartDate = liveInfo.ipuStartDate !== null ? removeUTCformat(liveInfo.ipuStartDate) : null
          liveInfo.ipuEndDate = liveInfo.ipuEndDate !== null ? removeUTCformat(liveInfo.ipuEndDate) : null
          liveInfo.spuStartDate = liveInfo.spuStartDate !== null ? removeUTCformat(liveInfo.spuStartDate) : null
          liveInfo.spuEndDate = liveInfo.spuEndDate !== null ? removeUTCformat(liveInfo.spuEndDate) : null

          // liveDi 기간이 없는 경우 빈 배열로 초기화
          if (!liveInfo.liveDiSchedules) { liveInfo.liveDiSchedules = [] }

          // Object.assign(liveinfo.value, liveInfo)
          liveinfo.value = liveInfo
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removeUTCformat = date => {
      let resultDate = null
      if (date.includes('T')) {
        [resultDate] = date.split('T')
      }
      return resultDate
    }

    const restrictToNumbers = () => {
      let min = String(liveinfo.value.minAge)
      min = min.replace(/[^\d\s]/g, '')
      liveinfo.value.minAge = min

      let max = String(liveinfo.value.maxAge)
      max = max.replace(/[^\d\s]/g, '')
      liveinfo.value.maxAge = max

      if (parseInt(liveinfo.value.minAge, 10) > parseInt(liveinfo.value.maxAge, 10)) {
        liveinfo.value.minAge = liveinfo.value.maxAge
      }
      if (liveinfo.value.minAge > 120) { liveinfo.value.minAge = 120 }
      if (liveinfo.value.maxAge > 120) { liveinfo.value.maxAge = 120 }
    }

    const isTopFixChange = () => {
      liveinfo.value.topFixLevel = 1 // topFix 변경시 1로 초기화
    }

    return {
      getInputGroupStyle,
      liveinfo,
      after,
      before,
      required,
      requiredIf,
      between,
      dateBetween,
      codes,
      validate,
      majorSelect,
      regionSelect,
      mediDeptSelect,
      showMajorSelect,
      showRegionSelect,
      showMediDeptSelect,
      resetLiveinfoData,
      getSchedIdxFirstOrLast,
      restrictToNumbers,
      editorOptions,
      isTopFixChange,
      addLiveDiSection,
      removeLiveDiSection,
      cleave,
      dateDisabled,
      isDarkMode,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.b-form-datepicker.form-control-sm .form-control-sm{
  line-height: 2;
}
</style>
